import React from "react";
import get from "lodash/get";
import isFinite from "lodash/isFinite";

import {
  AnswersTableRow,
  Section,
  Question,
  Circle,
  Answer,
} from "./CompareAnswers.styles";
import Markdown from "common/components/Markdown";
import {
  Field as FieldType,
  Answer as AnswerType,
  SectionType,
  CompareIcon,
} from "./index";
import { Question as QuestionType } from "shared/models/inputForm.types";
import ViewImages from "common/components/ViewImages";

type CaptionTypes = {
  showCaptionTooltip: boolean;
  clearTooltip(): any;
  setTooltip(params: any): any;
};

const getSeverity = (answer?: FieldType): number | null => {
  if (
    !answer ||
    !isFinite(answer.positivity) ||
    answer.positivity === undefined
  ) {
    return null;
  }

  const severity = Math.round(4 - answer.positivity * 4);

  return severity;
};

const hasAnswers = (
  question: Pick<QuestionType, "id">,
  visibleAnswers: AnswerType[],
): boolean => {
  return (
    visibleAnswers.filter(a =>
      Object.keys(a.fields).includes(question.id as string),
    ).length > 0
  );
};

type CellProps = {
  id: any;
  activeId: any;
  field: FieldType;
  numAnswers: number;
  answer: AnswerType;
  holvikaari?: boolean;
} & CaptionTypes;

type Attachment = Record<string, any> & {
  id: string;
  url: string;
  name: string;
};

export const Cell = (props: CellProps) => {
  const {
    id,
    activeId,
    field,
    showCaptionTooltip,
    numAnswers,
    clearTooltip,
    setTooltip,
    answer,
    holvikaari,
  } = props;

  const attachments: Attachment[] = get(field, "attachments", []);
  const caption = get(field, "caption") || "";

  const markdownCaption = get(field, "markdown_caption");

  const attributes =
    showCaptionTooltip && caption
      ? {
          onMouseEnter: (e: React.MouseEvent) => {
            setTooltip({
              content: markdownCaption ? (
                <Markdown source={caption} />
              ) : (
                caption
              ),
              type: "large",
              position: "top",
              target: e.target,
            });
          },
          onMouseLeave: clearTooltip,
        }
      : {};

  const renderCaption = !showCaptionTooltip && caption;

  const renderAttachments = attachments.length > 0;

  const severity = getSeverity(field);

  const images = renderAttachments
    ? attachments.map((attachment: Attachment) => ({ src: attachment.url }))
    : [];

  return (
    <Answer
      key={id}
      numAnswers={numAnswers}
      selected={!holvikaari && id === activeId}
      withBell={get(field, "icon") === "bell"}
      severity={severity}
      lang={answer.lang}
      {...attributes}
    >
      {severity === null ? null : <Circle severity={severity} />}

      <CompareIcon icon={get(field, "icon") || ""} />

      {get(field, "value")}

      {renderAttachments && <ViewImages images={images} />}

      {renderCaption && ": "}

      {renderCaption &&
        (markdownCaption ? <Markdown source={caption} /> : caption)}
    </Answer>
  );
};

type RowProps = {
  question: Pick<QuestionType, "id" | "text">;
  visibleAnswers: AnswerType[];
  activeId: number;
  isSubQuestion?: boolean;
  newStyles?: boolean;
  holvikaari?: boolean;
} & CaptionTypes;

const Row = (props: RowProps) => {
  const {
    visibleAnswers,
    newStyles,
    question,
    activeId,
    isSubQuestion,
    holvikaari,
    ...rest
  } = props;

  if (!question.text) return null;
  return (
    <AnswersTableRow newStyles={newStyles} key={question.id}>
      <Question
        isSubQuestion={isSubQuestion}
        numAnswers={visibleAnswers.length}
        holvikaari={holvikaari}
      >
        <Markdown source={question.text} />
      </Question>

      {visibleAnswers.map((answer, i) => {
        const id = answer.id || `${question.id}-${i}`;
        const field = answer.fields[question.id as any];
        return (
          <Cell
            key={id}
            id={id}
            activeId={activeId}
            field={field}
            answer={answer}
            numAnswers={visibleAnswers.length}
            holvikaari={holvikaari}
            {...rest}
          />
        );
      })}
    </AnswersTableRow>
  );
};

type Props = {
  activeId: any;
  section: SectionType;
  newStyles?: boolean;
  questionFilter: string;
  visibleAnswers: AnswerType[];
  isSubQuestion?: boolean;
  holvikaari?: boolean;
} & CaptionTypes;

const CompareSection = (props: Props) => {
  const {
    section: { name, questions } = {},
    newStyles,
    questionFilter,
    visibleAnswers,
    ...rest
  } = props;

  const questionsWithAnswers = questions?.filter(q => {
    const hasSubQuestions = !!q.sub_questions?.length;

    if (q.hidden_on_show) {
      return false;
    }

    if (!hasSubQuestions) {
      if (hasAnswers(q, visibleAnswers)) {
        return q;
      }
    }

    if (hasSubQuestions && q.sub_questions) {
      const subQuestionAnswers = q.sub_questions.filter(sq =>
        hasAnswers(sq, visibleAnswers),
      );

      if (subQuestionAnswers.length) {
        return q;
      }
    }
  });

  const visibleQuestions = questionsWithAnswers?.filter(
    question =>
      question.text?.toLowerCase().includes(questionFilter) ||
      question.sub_questions?.some(subQuestion =>
        subQuestion.text?.toLowerCase().includes(questionFilter),
      ),
  );

  // if neither question nor its subquestion has answers, hide the whole section
  if (
    !questionsWithAnswers ||
    questionsWithAnswers.length === 0 ||
    !visibleQuestions ||
    visibleQuestions.length === 0
  ) {
    return null;
  }

  return (
    <tbody>
      {name && (
        <AnswersTableRow newStyles={newStyles} key={name}>
          <Section colSpan={"100%" as any}>
            <Markdown source={name} />
          </Section>
        </AnswersTableRow>
      )}

      {visibleQuestions.map(question => {
        const { sub_questions = [] } = question;
        const visibleSubquestions = sub_questions.filter(subQuestion =>
          subQuestion.text?.toLowerCase().includes(questionFilter),
        );

        return [
          <Row
            key={question.id}
            question={question}
            newStyles={newStyles}
            visibleAnswers={visibleAnswers}
            {...rest}
          />,
        ].concat(
          visibleSubquestions.map(subQuestion => {
            return (
              <Row
                key={subQuestion.id}
                question={subQuestion}
                isSubQuestion={true}
                newStyles={newStyles}
                visibleAnswers={visibleAnswers}
                {...rest}
              />
            );
          }),
        );
      })}
    </tbody>
  );
};

export default CompareSection;
