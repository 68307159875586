import { SiteSettings } from "../holvikaari";

import articles from "./services/articles";
import calendar from "./services/calendar";
import ccda from "./services/ccda";
import patientCharacteristics from "./services/patientCharacteristics";

const api2 = (urls: Record<string, string> = {}) => {
  const baseUrls = { ...SiteSettings, ...urls };
  return {
    // sorted alphabetically
    ...articles(baseUrls),
    ...calendar(baseUrls),
    ...ccda(baseUrls),
    ...patientCharacteristics(baseUrls),
  };
};

export default api2;
