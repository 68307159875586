/* eslint-disable max-lines */
import React, { Validator } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";
import { withRouter, RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import OverflowDropdown from "./OverflowDropdown";
import PropTypes, { InferProps } from "prop-types";
import NavBar, { RightNav } from "./NavBar";
import { Language } from "common/models/language";
import { feedbackFormIdsByLanguage } from "common/utils/feedback_form_ids";
import { Icon } from "@netmedi/frontend-design-system";
import { endpoints } from "common/utils/endpoints";
import { post, track } from "common/utils/api";
import { User } from "common/models/user";
import { components, ControlProps, OptionProps } from "react-select";
import {
  ControlIcon,
  DomainLink,
  DomainSelect,
  GreenIcon,
  SelectHeader,
  StyledOption,
} from "./NavBar.styles";
import { SiteSettings } from "common/utils/holvikaari";
import { cleanUrlLocalePrefix } from "common/utils/general";

export const icons = {
  home: <Icon name="home" />,
  dashboard: <Icon name="dashboard" />,
  persons: <Icon name="persons" />,
  building: <Icon name="building" />,
  messages: <Icon name="messages" />,
  video: <Icon name="video" />,
  help: <Icon name="question_circle" />,
  absences: <Icon name="absences" />,
  user_search: <Icon name="user_search" />,
  create: <Icon name="user_create" />,
  forms: <Icon name="forms" />,
  view: <Icon name="view" />,
  direction_down: <Icon name="arrowhead_down" />,
  kebab: <Icon name="more_vertical" />,
  user: <Icon name="user" />,
  logout: <Icon name="logout" />,
  okCircle: <GreenIcon name="ok_circle" />,
  new_tab: <Icon name="new_tab" />,
};

export type SignedInNavBarProps = {
  paths: {
    root: string;
    clients: string;
    dashboard: string;
    conversations: string;
    video: string;
    signOut: string;
    editPerson: string;
    absences: string;
    newPerson: string;
    treatmentPrograms: string;
    inputForms: string;
    dataExports: string;
    onlineHelp: string;
    userManagement: string;
    teams: string;
  };
  logoPath: string;
  isStaff: boolean;
  isClient: boolean;
  isEagleEyeEnabled: boolean;
  isNonMedicalStaff: boolean;
  isKaikuAdministrator: boolean;
  isStudyMonitor: boolean;
  isDashboardEnabled: boolean;
  isMessagingEnabled: boolean;
  isKeycloakSsoLogin: boolean;
  newConversationsCount: number;
  currentUser: User;
  inputFormCount: number;
  locale: string;
  isOnlineHelpEnabled: boolean;
  onlineHelpLanguages: (
    | "de"
    | "en"
    | "es"
    | "fi"
    | "fr"
    | "fr-CH"
    | "it"
    | "it-CH"
    | "nl"
    | "sv"
    | "el"
    | "da"
    | "nb"
    | "et"
    | "tr"
    | "pt"
    | "ru"
    | "pl"
    | "en-US"
    | "es-US"
    | "es-419"
    | "en-AU"
    | "de-AT"
    | "de-CH"
    | "ar"
    | "ja"
    | "fr-CA"
    | "zh-Hans"
    | "zh-Hant"
    | "en-GB"
    | "en-CA"
    | "pt-BR"
    | "tl"
    | "ms"
    | "en-MY"
  )[];
  userHasAssignableRoles: boolean;
  hcpTeams: boolean;
  hideNavBar?: boolean;
};

const Option = ({ children, ...props }: OptionProps<any>): JSX.Element => (
  <StyledOption $active={props.isDisabled}>
    <components.Option {...props}>
      {props.data.icon && icons[props.data.icon as keyof typeof icons]}
      {children}
    </components.Option>
  </StyledOption>
);

const Control = ({ children, ...props }: ControlProps) => (
  <components.Control {...(props as any)}>
    <ControlIcon>{icons["okCircle"]}</ControlIcon> {children}
  </components.Control>
);

// Note regarding the `isStaff` and `isClient` props:
// The nav bar for non staff is probably legacy as the patients layout is entirely different
// but since a patient can still access the regular layout by e.g. going to the /feed url
// we still need to handle it here. Though it's probably safe to just return `null`
// since the patient is not supposed to go there anyway.
// The above is also valid for `isSignedIn` and the navbar being visible when the user is logged out
export default function SignedInNavBar(props: SignedInNavBarProps) {
  const intl = useIntl();

  const userDomains = props.currentUser.domains
    ? props.currentUser.domains
    : [];
  const siteHasMultipleDomain = SiteSettings.available_domains.length > 1;
  const showDomainSelect = userDomains.length > 5;

  const getFeedbackLink = (language: string, role: string) => {
    const feedbackFormId =
      feedbackFormIdsByLanguage[language] ||
      feedbackFormIdsByLanguage[language.substring(0, 2)] ||
      feedbackFormIdsByLanguage.en;

    const params =
      `prefill_Client=${encodeURIComponent(SiteSettings.client_name)}` +
      `&hide_Client=true&prefill_Sender=${encodeURIComponent(
        role,
      )}&hide_Sender=true` +
      `&hide_Short%20summary=true`;

    return `https://airtable.com/${feedbackFormId}?${params}`;
  };

  const feedbackFormLink = getFeedbackLink(
    props.locale,
    props.currentUser.role_id || "",
  );

  const showHomeIcon =
    (!props.isNonMedicalStaff && !props.isStudyMonitor) ||
    props.isKaikuAdministrator;
  const showUserManagementLeft =
    (props.isNonMedicalStaff || props.isStudyMonitor) &&
    !props.isKaikuAdministrator;
  const showPopulationDashboard =
    props.isEagleEyeEnabled &&
    (((props.isStaff || props.isStudyMonitor) && !props.isNonMedicalStaff) ||
      props.isKaikuAdministrator);
  const showPatients = props.isStaff;
  const showConversations = props.isMessagingEnabled && !props.isStudyMonitor;
  const showVideoConversations =
    SiteSettings.video_conversations && props.isStaff;
  const showTreatmentPrograms =
    props.isStaff || props.isNonMedicalStaff || props.isStudyMonitor;
  const showInputForms =
    (props.isStaff || props.isNonMedicalStaff) && props.inputFormCount;
  const showCreateNewClient =
    props.userHasAssignableRoles &&
    SiteSettings.show_create_new_patient_button &&
    (props.currentUser.role_id === "Nurse" ||
      props.currentUser.role_id === "Doctor");
  const showAbsenses = props.isStaff;
  const showOnlineHelp =
    (props.isStaff ||
      props.isNonMedicalStaff ||
      props.currentUser.role_id === "Admin" ||
      props.isStudyMonitor) &&
    props.isOnlineHelpEnabled &&
    props.onlineHelpLanguages.includes(props.locale as Language);
  const showFeedbackForm = props.isStaff || props.isNonMedicalStaff;
  const showUserManagementRight =
    !showUserManagementLeft && props.isKaikuAdministrator;

  const changeDomain = async (domain: string) => {
    await post(endpoints.changeDomain, { new_domain: domain });
    window.location.reload();
  };

  const domainToOption = (domain: string) => ({
    value: domain,
    label: intl.formatMessage({
      id: `client.domains.${domain}`,
    }),
    icon: props.currentUser.selected_domain === domain ? "okCircle" : null,
    isDisabled: props.currentUser.selected_domain === domain,
  });
  if (props.hideNavBar) {
    return null;
  } else {
    return (
      <NavBar
        logoPath={props.logoPath}
        rootPath={props.paths.root}
        leftNav={
          <OverflowDropdown
            moreId={"navbar.more"}
            elements={
              [
                showHomeIcon && (
                  <Link
                    url={props.paths.root}
                    icon="home"
                    id="navbar.front_page"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showUserManagementLeft && (
                  <Link
                    url={props.paths.userManagement}
                    icon="persons"
                    id="navbar.user_management"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showPopulationDashboard && (
                  <Link
                    url={props.paths.dashboard}
                    icon="dashboard"
                    id="navbar.dashboard.population_dashboard"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showPatients && (
                  <Link
                    url={props.paths.clients}
                    icon="persons"
                    id="navbar.customers"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showConversations && (
                  <Link
                    url={props.paths.conversations}
                    icon="messages"
                    id="navbar.conversations"
                    anchorId="conversation-link"
                    currentUserId={props.currentUser.id.toString()}
                  >
                    <span
                      className={cn({
                        badge: true,
                        "badge-important": props.newConversationsCount > 0,
                        "badge-muted": props.newConversationsCount <= 0,
                      })}
                    >
                      {props.newConversationsCount}
                    </span>
                  </Link>
                ),
                showVideoConversations && (
                  <Link
                    className={cn({
                      "client-video-conversation-link": !props.isStaff,
                    })}
                    url={props.paths.video}
                    icon="video"
                    id="vhs.video_conversation"
                    anchorId="video-conversation-link"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showTreatmentPrograms && (
                  <Link
                    url={props.paths.treatmentPrograms}
                    id="feed.index.edit_treatment_programs"
                    icon="user_search"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                props.hcpTeams && (
                  <Link
                    url={props.paths.teams}
                    id="feed.index.edit_teams"
                    icon="persons"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showInputForms && (
                  <Link
                    url={props.paths.inputForms}
                    id="values.nav.forms"
                    icon="forms"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showCreateNewClient && (
                  <Link
                    url={props.paths.newPerson}
                    id="feed.index.create_new_client"
                    icon="create"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showUserManagementRight && (
                  <Link
                    url={props.paths.userManagement}
                    icon="persons"
                    id="navbar.user_management"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showAbsenses && (
                  <Link
                    url={props.paths.absences}
                    id="navbar.your_vacations"
                    icon="absences"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showOnlineHelp && (
                  <Link
                    url={props.paths.onlineHelp}
                    icon="help"
                    id="navbar.online_help"
                    className="onlineHelp"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                props.paths.dataExports && (
                  <Link
                    url={props.paths.dataExports}
                    id="navbar.data_exports"
                    icon="view"
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
                showFeedbackForm && (
                  <Link
                    url={feedbackFormLink}
                    target="_blank"
                    icon="new_tab"
                    id="navbar.send_feedback"
                    className="sendFeedback"
                    external={true}
                    currentUserId={props.currentUser.id.toString()}
                  />
                ),
              ].filter(link => !!link) as any
            }
          />
        }
        rightNav={
          <RightNav
            navElements={[
              renderUserDropdown(
                []
                  .concat(
                    props.isStaff || props.isStudyMonitor
                      ? (
                          [
                            // If the user is only associated with 1 domain, don't offer to change it, since they can't
                            userDomains.length > 1 && {
                              disabled: true,
                              child: (
                                <SelectHeader>
                                  <FormattedMessage id="navbar.change_domain" />
                                </SelectHeader>
                              ),
                            },
                          ] as LinkProps["dropdownChildren"] as any
                        )
                          .concat(
                            siteHasMultipleDomain && !showDomainSelect
                              ? userDomains.map((domain: string) => ({
                                  disabled:
                                    domain ===
                                    props.currentUser.selected_domain,
                                  child: (
                                    <DomainLink
                                      onClick={() => changeDomain(domain)}
                                      className={
                                        domain ===
                                        props.currentUser.selected_domain
                                          ? "currentDomain"
                                          : ""
                                      }
                                    >
                                      {domain ===
                                      props.currentUser.selected_domain ? (
                                        <>{icons["okCircle"]}</>
                                      ) : (
                                        <div className="noIcon" />
                                      )}
                                      <FormattedMessage
                                        id={`client.domains.${domain}`}
                                      />
                                    </DomainLink>
                                  ),
                                }))
                              : [], // Don't show the domains if the Site doesn't have more than 1
                          )
                          .concat(
                            showDomainSelect
                              ? [
                                  <React.Fragment key="domain-dropdown">
                                    <DomainSelect
                                      components={{ Option, Control }}
                                      options={userDomains.map(
                                        (domain: string) =>
                                          domainToOption(domain),
                                      )}
                                      isSearchable={false}
                                      isClearable={false}
                                      defaultValue={domainToOption(
                                        props.currentUser.selected_domain,
                                      )}
                                      onChange={(e: any) =>
                                        changeDomain(e.value)
                                      }
                                    />
                                  </React.Fragment>,
                                ]
                              : [],
                          )
                      : [],
                  )
                  .concat([
                    (props.isStaff || props.isStudyMonitor) &&
                      siteHasMultipleDomain && <div className="divider" />,
                    <RouterLink key={1} to={props.paths.editPerson}>
                      {icons["user"]}
                      <FormattedMessage id="navbar.own_information" />
                    </RouterLink>,
                  ] as any)
                  .concat([
                    !props.isKeycloakSsoLogin && (
                      <a key={3} href={props.paths.signOut}>
                        {icons["logout"]}
                        <FormattedMessage id="navbar.sign_out" />
                      </a>
                    ),
                  ] as any),
              ),
            ]}
          />
        }
      />
    );
  }

  function renderUserDropdown(
    userDropdownItems: LinkProps["dropdownChildren"],
  ) {
    return (
      <Link
        url="#"
        label={
          <>
            {props.currentUser.last_name}, {props.currentUser.first_name}
            {siteHasMultipleDomain && (
              <>
                <br />
                <FormattedMessage
                  id={`client.domains.${props.currentUser.selected_domain}`}
                />
              </>
            )}
          </>
        }
        dropdownChildren={userDropdownItems?.filter(link => !!link)}
        external
      />
    );
  }
}

const SuperDuperRealLink = (props: any) => {
  const { url, external, children, ...rest } = props;
  if (external)
    return (
      <a href={url} {...(rest as any)}>
        {children}
      </a>
    );
  return (
    <RouterLink to={cleanUrlLocalePrefix(url)} {...(rest as any)}>
      {children}
    </RouterLink>
  );
};

function sendTrackerEvent(
  source: string,
  destination: string,
  currentUserId: string,
) {
  if (destination !== "#") {
    // Don't track dropdowns
    track("Top bar navigation", {
      user_id: currentUserId,
      category: "Navigation",
      source: source,
      destination: destination,
    });
  }
}

const trimUrlStringForTrackerEvent = (url: string) => {
  const splittedUrl = url.split("/");
  return "/" + splittedUrl[splittedUrl.length - 1];
};

const Link = withRouter(
  ({
    url,
    target,
    icon,
    id,
    label,
    location,
    children,
    className,
    anchorId,
    dropdownChildren,
    external,
    currentUserId,
  }: LinkProps) => {
    return (
      <li className={cn(className, { dropdown: !!dropdownChildren })}>
        <SuperDuperRealLink
          url={url}
          external={external}
          target={target || undefined}
          className={cn({
            active: url === location.pathname,
            "dropdown-toggle": !!dropdownChildren,
            dropdownContainer: !!dropdownChildren,
          })}
          id={anchorId || undefined}
          {...(dropdownChildren ? { "data-toggle": "dropdown" } : {})}
          onClick={() =>
            sendTrackerEvent(
              location.pathname,
              trimUrlStringForTrackerEvent(url || ""),
              currentUserId || "",
            )
          }
        >
          {icon && (icons as any)[icon]}
          {!!id && (
            <>
              {" "}
              <FormattedMessage id={id} />
            </>
          )}{" "}
          {!!label && <div className="linkLabel">{label}</div>}
          {!!children && <span>{children}</span>}
          {!!dropdownChildren && <> {icons["direction_down"]}</>}
        </SuperDuperRealLink>
        {!!dropdownChildren && (
          <ul className="dropdown-menu">
            {dropdownChildren.map((dropdownChild, i) =>
              "child" in (dropdownChild as any) ? (
                <li
                  key={i}
                  className={cn({ disabled: (dropdownChild as any).disabled })}
                >
                  {(dropdownChild as any).child}
                </li>
              ) : (
                <li key={i}>{dropdownChild}</li>
              ),
            )}
          </ul>
        )}
      </li>
    );
  },
);

// TODO: Clean this to be typescript types.
const linkPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  anchorId: PropTypes.string,
  dropdownChildren: PropTypes.arrayOf(
    PropTypes.oneOfType<
      Validator<
        | PropTypes.ReactNodeLike
        | { child: PropTypes.ReactNodeLike; disabled: boolean }
      >
    >([
      PropTypes.node.isRequired,
      PropTypes.shape({
        child: PropTypes.node.isRequired,
        disabled: PropTypes.bool.isRequired,
      }).isRequired,
    ]).isRequired,
  ),
  external: PropTypes.bool,
  currentUserId: PropTypes.string,
};
Link.propTypes = linkPropTypes;
type LinkProps = RouteComponentProps & InferProps<typeof linkPropTypes>;
