import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  typography,
  mediaQueries,
  scaffolding,
  symptomGrades,
  spacing,
  colors,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

import { Icon } from "@netmedi/frontend-design-system";
import Page from "common/components/Page";
import { FormFieldInput } from "common/components/Form/FormField.styles";

const rowHeight = 48;

const severityColor = (severity: number) => {
  switch (severity) {
    case 0:
      return symptomGrades.severity0;
    case 1:
      return symptomGrades.severity1;
    case 2:
      return symptomGrades.severity2;
    case 3:
      return symptomGrades.severity3;
    case 4:
      return symptomGrades.severity4;
    default:
      return symptomGrades.emptyCell;
  }
};

const questionColumnWidth = (numAnswers: number) => {
  if (numAnswers > 2) {
    return `${100 / (numAnswers + 1)}%`;
  }
  switch (numAnswers) {
    case 1:
      return "40%";
    case 2:
      return "30%";
  }
};

const answerColumnWidth = (numAnswers: number) => {
  if (numAnswers > 2) {
    return `${100 / (numAnswers + 1)}%`;
  }
  switch (numAnswers) {
    case 1:
      return "60%";
    case 2:
      return "35%";
  }
};

export const StyledPage = styled(Page)<{ holvikaari?: boolean }>`
  ${({ holvikaari }) => !!holvikaari && `width: initial;`}
`;

export const Score = styled.tr<{ holvikaari?: boolean }>`
  font-weight: 500;
  &:hover td {
    opacity: 1;
    background: ${colors.blue50};
  }
  ${({ holvikaari }) =>
    holvikaari && rtl`border-left: 1px solid ${scaffolding.gridBorderColor};`}
`;

export const AnswerDateHeader = styled.span`
  position: absolute;
  pointer-events: none;
`;

export const AnswersTableData = styled.td<{
  selected?: boolean;
  holvikaari?: boolean;
}>`
  ${({ holvikaari }) =>
    holvikaari &&
    rtl`
      border-left: 1px solid ${scaffolding.gridBorderColor};
    `}
  ${rtl`
    min-width: 100px;
    position: relative;
    overflow: hidden;
    padding: 0.75rem;
    border-right: 1px solid ${scaffolding.gridBorderColor};
    border-top: 1px solid ${scaffolding.gridBorderColor};
    transition: all 0.2s;
`}

  ${({ selected }) => !!selected && `background: ${colors.blue50};`}

  ${mediaMax(mediaQueries.small)} {
    font-size: ${typography.fontSizeMedium};
  }
`;

type AnswerProps = {
  severity: number | null;
  numAnswers: number;
  withBell?: boolean;
};

export const Answer = styled(AnswersTableData).attrs(() => ({
  "data-testid": `compare-answers-answer`,
}))<AnswerProps>`
  width: ${({ numAnswers }) => answerColumnWidth(numAnswers)};
  white-space: pre-wrap;

  &:hover {
    opacity: 1;
    background: ${colors.blue50};
  }

  ${({ withBell }) =>
    withBell &&
    css`
      color: ${colors.red700};
      font-weight: 500;
    `}
`;

export const TableHeader = styled.th<{
  selected?: boolean;
}>`
  ${({ selected }) =>
    !!selected &&
    `
      background: ${colors.blue500};
      color: ${colors.white};
    `}
`;

type CircleProps = { severity: number | null };

export const Circle = styled.span<CircleProps>`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  ${rtl`
    margin-right: ${spacing.xxs}px;
  `}

  ${({ severity }) =>
    severity !== null && `background: ${severityColor(severity)}`}
`;

export const Arrow = styled.i`
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: ${rowHeight}px;
  transition: all 0.2s;

  @media (max-width: ${mediaQueries.small}px) {
    width: 50%;
  }
`;

export const ArrowForward = styled(Arrow)`
  ${rtl`
    text-align: right;
    right: 0;
    padding-right: 0.5em;

    &:hover {
      padding-right: 0.25em;
    }
  `}
`;

export const ArrowBack = styled(Arrow)`
  ${rtl`
    left: 0;
    padding-left: 0.5em;

    &:hover {
      padding-left: 0.25em;
    }`}
`;

export const Section = styled(AnswersTableData).attrs(() => ({
  "data-testid": "compare-answers-section",
}))`
  font-weight: 500;
  padding-top: ${spacing.s}px;
`;

export const AnswersTable = styled.table.attrs(() => ({
  "data-testid": "compare-answers-answers-table",
}))<{ newStyles: boolean }>`
  ${rtl`
    border: 0;
    border-bottom: 1px solid ${scaffolding.gridBorderColor};
    border-spacing: 0;
    width: 100%;
    margin-right: -24px;
    height: 100%;
    overflow: hidden;
  `}
  margin-bottom: 50px;

  ${({ newStyles }) =>
    newStyles &&
    css`
      p {
        margin: 0;
      }
    `}
`;

export const Question = styled(AnswersTableData).attrs(() => ({
  "data-testid": "compare-answers-question",
}))<{ isSubQuestion?: boolean; numAnswers: number }>`
  ${({ isSubQuestion }) => !!isSubQuestion && `padding-left: 2rem;`}
  width: ${({ numAnswers }) => questionColumnWidth(numAnswers)};
`;

export const AnswersTableRow = styled.tr<{ newStyles?: boolean }>`
  height: ${rowHeight}px;

  ${({ newStyles }) =>
    newStyles &&
    css`
      &:nth-child(even) {
        background: ${colors.gray50};
      }
    `}

  &:hover td {
    opacity: 1;
    background: ${colors.blue50};
  }
`;

export const Headers = styled(AnswersTableRow)<{ newStyles: boolean }>`
  height: ${({ newStyles }) => (newStyles ? "4rem" : "2rem")};
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled(FormFieldInput).attrs(() => ({
  "data-testid": "search-input",
}))`
  ${rtl`
    padding-left: 2rem;
    width: 100%;
  `}
`;

export const SearchIcon = styled(Icon)`
  ${rtl`
    position: absolute;
    top: 6px;
    left: 6px;
    color: ${colors.gray400};
  `}
`;

export const HeaderCell = styled.div<{
  selected?: boolean;
  backLink?: boolean;
  forwardLink?: boolean;
}>`
  ${rtl`
    position: relative;
    height: 100%;
    text-align: left;
    font-weight: 400;
    padding: 0.7rem;
    height: ${rowHeight}px;
  `}

  ${({ selected }) =>
    !!selected &&
    css`
      background: ${colors.blue500};
      color: ${colors.white};
    `}

  ${({ backLink, selected }) =>
    backLink &&
    rtl`
      padding-left: 2em;
      &:hover {
        background: ${selected ? colors.blue500 : symptomGrades.emptyCell};
      }
    `}

  ${({ forwardLink, selected }) =>
    forwardLink &&
    rtl`
      padding-right: 2em;

      &:hover {
        background: ${selected ? colors.blue500 : symptomGrades.emptyCell};
      }
    `}
`;
