import { EndpointParams } from "../api2.types";
import { SiteSettings } from "../../holvikaari";
import { get, post, put, del } from "../../api";

const patientCharacteristics = ({
  patient_characteristics_api_url,
}: Record<string, string> = {}) => {
  const baseUrl = () =>
    `${patient_characteristics_api_url}/diagnoses/${SiteSettings.site}`;

  return {
    createDiagnosis: ({
      diagnosisData,
    }: EndpointParams["createDiagnosis"]) => ({
      post: () => post(baseUrl(), diagnosisData),
    }),
    deleteDiagnosis: ({ diagnosisId }: EndpointParams["deleteDiagnosis"]) => {
      return { delete: () => del(`${baseUrl()}/${diagnosisId}`) };
    },
    getDiagnoses: ({ patientId }: EndpointParams["getDiagnoses"]) => {
      return {
        get: () =>
          get(
            `${baseUrl()}${patientId ? `?patientId=${patientId}` : ""}`,
            false,
            {},
            { throwErrors: true },
          ),
      };
    },
    updateDiagnosis: ({
      diagnosisId,
      diagnosisData,
    }: EndpointParams["updateDiagnosis"]) => {
      return { put: () => put(`${baseUrl()}/${diagnosisId}`, diagnosisData) };
    },
  };
};

export default patientCharacteristics;
