import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  flatMap,
  minBy,
  maxBy,
  debounce,
  last,
  isEmpty,
  isEqual,
  isNil,
  noop,
} from "lodash";
import { FormattedMessage } from "react-intl";

import SymptomTable, {
  VALID_INTERVALS,
  ValidInterval,
  timeSlots,
  DataType,
} from "./SymptomTable";
import SymptomTableControls from "./SymptomTableControls";
import { NoSelect, NoSymptomsReported } from "./SymptomTable.styles";
import { Spinner } from "@netmedi/frontend-design-system";
import SymptomTableCompact from "../SymptomTableCompact";
import { getSlotsForInterval } from "./getSlotsForInterval";
import { StepsProps } from "../SymptomTableCompact/types";
import { getLastStepsItem, hasStepsData } from "./helpers";
import { MakePartial } from "common/utils/types";

import { connect } from "react-redux";
import { getAllSymptoms, getSteps } from "client/actions/client";
import { setTooltip } from "common/actions/tooltip";
import { RootState } from "store";
import { setLoadingStatus } from "common/actions/loading";
import { RequestStatus } from "common/reducers/loading";
import { formatDate } from "common/utils/general";
import dayjs, { Dayjs } from "dayjs";

const limitTime = (time: Dayjs, min?: Dayjs, max?: Dayjs) =>
  dayjs(
    time.isBefore(min) ? min : time.isAfter(max) ? max : time,
    undefined,
    true,
  );

const getItems = (rows: SymptomTableProps["rows"]) => {
  const data = flatMap(rows, ({ data }) => data).filter(
    (data): data is DataType => !isNil(data),
  );
  const lastDates = flatMap(
    data,
    ({ date }: { date: Parameters<typeof dayjs>[0] }) =>
      dayjs(date || 0).unix(),
  );
  const firstItem = dayjs(1000 * Math.min(...lastDates));
  const lastItem = dayjs(1000 * Math.max(...lastDates));
  return { firstItem, lastItem };
};

const combineRows = (
  grades: SymptomTableProps["rows"][number]["data"],
  start: Dayjs,
  end: Dayjs,
) => {
  const min = minBy(grades, "grade"),
    max = maxBy(grades, "grade");
  if (!min || !max) return null;
  return {
    ...max,
    grade_min: min.grade,
    start,
    end,
    sources: flatMap(grades, "sources"),
    ...(min.relative_positivity && max.relative_positivity
      ? {
          relative_positivity: Math.min(
            min.relative_positivity,
            max.relative_positivity,
          ),
          inverse_positivity: min.relative_positivity > max.relative_positivity,
        }
      : undefined),
  };
};

const viewInTimeRange = (
  row: SymptomTableProps["rows"][number],
  start: Dayjs,
  end: Dayjs,
) => {
  // Alphabetical comparison 50x faster than dayjs
  const start_stamp = formatDate(start),
    end_stamp = formatDate(end);
  const data = row.data?.filter((data): data is DataType => !isNil(data)) ?? [];
  const inRange = data.filter(
    ({ date }) => date >= start_stamp && date <= end_stamp,
  );

  // Continue bar over calendar entry empty columns, if there's less than 30d between closest
  // entries outside of current interval.
  if (inRange && !inRange.length && row.item_type === "CalendarEntry") {
    const unixStamps = data.map(({ date }) => dayjs(date).unix());
    const unixStart = dayjs(start.clone()).unix(),
      unixEnd = dayjs(end.clone()).unix();
    const maxBefore = dayjs(
        1000 * Math.max(...unixStamps.filter(t => t < unixStart)),
        undefined,
        true,
      ),
      minAfter = dayjs(
        1000 * Math.min(...unixStamps.filter(t => t > unixEnd)),
        undefined,
        true,
      );
    const diff = minAfter.diff(maxBefore, "days");
    if (diff < 30) {
      return [
        {
          ...data[0],
          date: formatDate(dayjs(500 * (unixStart + unixEnd))),
          sources: [],
        },
      ];
    }
  }

  return inRange;
};

const DATA_CELL_WIDTH = 62;
const TITLE_COLUMN_WIDTH = 200;
const DEFAULT_CLIENT_WIDTH = 800;
const getItemsWidth = (rootRef?: React.RefObject<any>) => {
  const clientWidth = rootRef?.current?.clientWidth || DEFAULT_CLIENT_WIDTH;
  const rootWidth = clientWidth - DATA_CELL_WIDTH;
  return Math.max(
    1,
    Math.floor((rootWidth - TITLE_COLUMN_WIDTH) / DATA_CELL_WIDTH),
  );
};

const mapStateToProps = (state: RootState) => ({
  steps: state.client.steps,
  rows: state.client.symptom_table_rows,
  loadingSymptoms: state.loading.symptoms,
});

const mapDispatchToProps = {
  getAllRows: getAllSymptoms,
  setLoading: (status: RequestStatus, entityId: string) =>
    setLoadingStatus("symptoms", status, entityId),
  getSteps,
  setTooltip,
};

type stateProps = ReturnType<typeof mapStateToProps>;
type dispatchProps = typeof mapDispatchToProps;

export type SymptomTableProps = MakePartial<dispatchProps, "setLoading"> &
  MakePartial<stateProps, "loadingSymptoms"> & {
    client_id: string;
    viewed_as_staff: boolean;
    // we might not want to debounce during tests
    debounceHandlers?: boolean;
  } & StepsProps;

type View = {
  ending: Dayjs;
  interval: ValidInterval;
};

const useComponentWillReceiveProps = <T,>(
  nextProps: T,
  callback: (oldProps: T) => void,
) => {
  const props = React.useRef<T>(nextProps);

  useEffect(() => {
    callback(props.current);
    props.current = nextProps;
  });
};

// eslint-disable-next-line max-statements
const SymptomTableRenderer = ({
  rows = [],
  steps,
  loadingSymptoms = {},
  client_id,
  viewed_as_staff,
  debounceHandlers = true,
  getAllRows,
  getSteps,
  setTooltip,
  setLoading,
}: SymptomTableProps) => {
  const [{ firstItem, lastItem }, setItems] = useState<{
    firstItem: Dayjs;
    lastItem: Dayjs;
  }>(getItems(rows));
  const [filteredRows, setFilteredRows] = useState<SymptomTableProps["rows"]>(
    [],
  );
  const [itemsWidth, setItemsWidth] = useState(getItemsWidth());
  const [view, setView] = useState<View>({
    ending: dayjs(),
    interval: "week",
  });

  const rootRef = useRef<any>();
  const tableRef = useRef<any>();

  useEffect(() => {
    attachListeners();
    loadAllRows();
    updateRange();
    return () => detachListeners();
  }, []);

  const updateRange = (newRows = rows) => {
    setItems(getItems(newRows));
  };

  const loadAllRows = () => {
    if ((loadingSymptoms[client_id] ?? "idle") === "idle") {
      const setStatus = setLoading || (() => {});

      setStatus("loading", client_id);
      getAllRows(client_id)
        .then(() => setStatus("succeeded", client_id))
        .catch(() => setStatus("failed", client_id));
    }
  };

  useEffect(() => {
    onViewChange({
      ending: lastItem?.endOf(view.interval),
    });
  }, [lastItem]);

  useEffect(() => {
    // using window.requestAnimationFrame messes up tests
    debounceHandlers
      ? window.requestAnimationFrame(() => {
          filterRows();
        })
      : filterRows();
  }, [view]);

  const componentWillReceiveProps = (prevRows: SymptomTableProps["rows"]) => {
    if (!isEqual(prevRows, rows)) {
      updateRange(rows);
    }
  };
  useComponentWillReceiveProps(rows, componentWillReceiveProps);

  const attachListeners = () => {
    window.addEventListener("resize", setSize);
    window.addEventListener("mousewheel", onScroll as EventListener);
    window.addEventListener("DOMMouseScroll", onScroll as EventListener);
  };

  const detachListeners = () => {
    window.removeEventListener("resize", setSize);
    window.removeEventListener("mousewheel", onScroll as EventListener);
    window.removeEventListener("DOMMouseScroll", onScroll as EventListener);
  };

  const getHoveredTime = (ev: WheelEvent) => {
    const { ending, interval } = view;
    const { pageX } = ev;
    const tableEl = tableRef.current;
    if (!tableEl) return;
    const headerElements = tableEl.querySelectorAll("thead th");
    const [firstX, lastX] = [1, headerElements.length - 1]
      .map(idx => headerElements[idx].getBoundingClientRect())
      .map((rect, idx) => rect[idx === 0 ? "left" : "right"] + window.scrollX);
    const relativeX = (pageX - firstX) / (lastX - firstX);
    if (relativeX < 0 || relativeX > 1) return;
    const slots = timeSlots(itemsWidth, dayjs(ending), interval);
    const [firstSlotStart, lastSlotEnd] = [
      slots[0][0].unix(),
      (last(last(slots)) as Dayjs).unix(),
    ];
    return dayjs(
      (firstSlotStart + relativeX * (lastSlotEnd - firstSlotStart)) * 1000,
      undefined,
      true,
    );
  };

  const zoom = (centerTime: Dayjs, direction: 1 | -1) => {
    const newIndex = VALID_INTERVALS.indexOf(view.interval) + direction;
    const newInterval = VALID_INTERVALS[newIndex];
    if (newInterval) {
      const { ending } = view;
      const endTime =
        centerTime &&
        dayjs(centerTime.clone()).add(Math.round(itemsWidth / 2), newInterval);
      const newEnding = limitTime(
        endTime ? endTime : ending,
        firstItem,
        lastItem,
      );
      onViewChange({
        interval: newInterval,
        ending: newEnding.endOf(newInterval),
      });
    }
  };

  const throttledOnScrollNoDebounce = (ev: WheelEvent) => {
    const { ending, interval } = view;
    const direction = ev.deltaY > 0 ? 1 : -1;
    const defaultHovered = dayjs(ending.clone()).subtract(
      Math.round(itemsWidth / 2),
      interval,
    ); // Zoom out, remain center

    // Zoom in, center cursor

    const variable =  (getHoveredTime(ev) ?? defaultHovered); //prettier-ignore
    const hoveredTime = direction > 0 ? defaultHovered : variable;
    zoom(hoveredTime, direction);
  };
  const throttledOnScroll = useMemo(
    () =>
      debounceHandlers
        ? debounce(throttledOnScrollNoDebounce, 200, {
            leading: true,
            trailing: false,
          })
        : throttledOnScrollNoDebounce,
    [debounceHandlers],
  );

  const onScroll = (ev: WheelEvent) => {
    if (ev && ev.ctrlKey) {
      ev.preventDefault();
      throttledOnScroll(ev);
    }
  };

  const setSizeNoDebounce = () => {
    const itemsWidth = getItemsWidth(rootRef);
    setItemsWidth(itemsWidth);
  };
  const setSize = useMemo(
    () =>
      debounceHandlers ? debounce(setSizeNoDebounce, 400) : setSizeNoDebounce,
    [debounceHandlers],
  );

  // Calculate the size on mount
  useEffect(setSizeNoDebounce, []);

  const getNumberOfSlots = (): number => {
    const { interval } = view;
    const mediaQuery = window.matchMedia("(max-width: 640px)");
    const isSmallScreen = mediaQuery.matches;

    if (isSmallScreen && firstItem?.isValid() && lastItem?.isValid()) {
      const slotsForInterval = getSlotsForInterval({
        firstItem,
        lastItem,
        interval,
      });
      if (itemsWidth > slotsForInterval) {
        return itemsWidth;
      }
      return slotsForInterval;
    }

    return itemsWidth;
  };

  const filterRows = () => {
    const { ending, interval } = view;
    const slots = timeSlots(getNumberOfSlots(), dayjs(ending), interval);
    const newFilteredRows = rows.map(row => ({
      ...row,
      data: slots.map(([start, end]) =>
        combineRows(viewInTimeRange(row, start, end), start, end),
      ),
    }));
    setFilteredRows(newFilteredRows);

    // Expose for testing
    window.symptom_table_view = {
      start: formatDate(slots[0][0]),
      end: formatDate(last(last(slots)) as Dayjs),
      interval,
    };
  };

  const onViewChange = (changes: Partial<View>) => {
    setView({ ...view, ...changes });
  };

  const onIntervalChange = (interval: ValidInterval) => {
    const { ending } = view;
    onViewChange({
      interval,
      ending: limitTime(ending, firstItem, lastItem).endOf(interval),
    });
    updateRange();
  };

  const loading = loadingSymptoms[client_id] === "loading";

  const mediaQuery = window.matchMedia("(max-width: 640px)");
  const isSmallScreen = mediaQuery.matches;

  if (isSmallScreen) {
    return (
      <NoSelect data-testid="symptom-table-compact">
        {loading || !itemsWidth ? (
          <Spinner noPadding />
        ) : isEmpty(filteredRows) ? (
          <NoSymptomsReported>
            <FormattedMessage id="symptom_table.no_reported_data" />
          </NoSymptomsReported>
        ) : (
          <div>
            <SymptomTableCompact
              {...view}
              startDate={firstItem}
              endDate={lastItem}
              onIntervalChange={onIntervalChange}
              rows={filteredRows}
              onZoom={noop}
              viewedAsStaff={viewed_as_staff}
              client_id={client_id}
              steps={steps}
              getSteps={getSteps}
              setTooltip={setTooltip}
            />
          </div>
        )}
      </NoSelect>
    );
  }

  const lastTimelineItem = hasStepsData(steps)
    ? getLastStepsItem(steps.data.observations)
    : lastItem;

  return (
    <NoSelect ref={rootRef} data-testid="symptom-table">
      {loading || !itemsWidth ? (
        <Spinner noPadding />
      ) : isEmpty(filteredRows) ? (
        <NoSymptomsReported>
          <FormattedMessage id="symptom_table.no_reported_data" />
        </NoSymptomsReported>
      ) : (
        <div>
          <SymptomTableControls
            {...view}
            onChange={onViewChange}
            firstItem={firstItem as Dayjs}
            lastItem={lastTimelineItem}
            itemsWidth={itemsWidth}
          />
          <SymptomTable
            {...view}
            getSteps={getSteps}
            setTooltip={setTooltip}
            client_id={client_id}
            steps={steps}
            startDate={firstItem}
            endDate={lastItem}
            onIntervalChange={onIntervalChange}
            rows={filteredRows}
            ref={tableRef}
            onZoom={zoom}
            viewedAsStaff={viewed_as_staff}
          />
        </div>
      )}
    </NoSelect>
  );
};

export const SymptomTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SymptomTableRenderer);

export default SymptomTableRenderer;
