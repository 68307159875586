import React, { useEffect, useMemo, useState } from "react";
import { Choice, FormField, PhoneInput, Select } from "common/components/Form";
import { FormattedMessage, IntlShape, injectIntl } from "react-intl";
import { Field, Form } from "react-final-form";
import { PlainFormField } from "common/components/Form/FormField";
import { SubmitButton } from "client/components/Profile/Profile.styles";
import { endpoints, post } from "common/utils/api";
import { validationRegexp } from "common/utils/general";
import { ButtonExtras, Card, Icon } from "@netmedi/frontend-design-system";
const { LinkButton } = ButtonExtras;
import {
  ErrorMsg,
  FieldDescription,
  InviteInformationDiv,
} from "./InvitePatients.styles";
import { User } from "common/models/user";
import IxrsProfileDetails, { IxrsProfile } from "./IxrsProfileDetails";
import { SiteSettings } from "common/utils/holvikaari";
import { showErrorFlash } from "hcp/utils/flash";
import { useSelector } from "react-redux";
import { selectUser } from "common/reducers/user";
import { withErrorBoundary } from "common/utils/errorBoundary";

interface DomainOption {
  name: string;
  value: string;
  is_anonymous: boolean;
}

export type InvitePatientsProps = {
  domains: Array<DomainOption>;
  languages: Array<any>;
  ask_phone_number: boolean;
  ask_person_id: boolean;
  ask_study_id: boolean;
  user: User;
  ixrs_profile?: IxrsProfile;
  treatment_program_id?: number;
  intl: IntlShape;
  client: User;
};

interface Invite {
  email: string;
  language: string;
  phone: string;
  person_id: string;
  study_id: string;
  treatment_program_id?: number;
  domain: string[];
  // Name and gender does not have fields. Those will have values when inviting using external login (sso)
  first_name?: string;
  last_name?: string;
  gender?: string;
}

const initialValues: Invite = {
  email: "",
  language: "",
  phone: "",
  person_id: "",
  study_id: "",
  domain: [],
};

const save = (values: any, intl: IntlShape, user: User) => {
  const win: Window = window;
  post(endpoints.invites.create, {
    email: values.email,
    language: values.language.value,
    phone: values.phone,
    person_id: values.person_id,
    study_id: values.study_id,
    treatment_program_id: values.treatment_program_id,
    domains: values.domain,
    first_name: values.first_name,
    last_name: values.last_name,
    gender: values.gender,
  })
    .then(invite => {
      if (user.role_id === "NonMedicalStaff") {
        win.location = `/manage/people`;
      } else {
        win.location = `/clients/${invite.client_id}/follows`;
      }
    })
    .catch(error => {
      if (error.data.errors) {
        showErrorFlash(error.data.errors);
      } else {
        showErrorFlash(
          intl.formatMessage({
            id: "invites.new.error_message_for_unknown_error",
          }),
        );
      }
    });
};

const updatePhoneCountry = () => {};

const handleDomainChange = (
  onChange: any,
  event: any,
  value: any,
  domains: DomainOption[],
) => {
  if (event.target.checked) {
    let updatedValues = [...(value ?? []), event.target.value];
    const updatedDomains = updatedValues.map(d =>
      domains.find(d2 => d2.value === d),
    );

    if (updatedDomains.some(d => d?.is_anonymous)) {
      updatedValues = updatedDomains
        .filter(d => d?.is_anonymous)
        .map(d => d?.value);
    }

    onChange(updatedValues);
  } else {
    onChange((value ?? []).filter((v: any) => v !== event.target.value));
  }
};

const domainsCheckboxes = (domains: Array<DomainOption>, disabled: boolean) => {
  return (
    <div>
      <Field name="domain" validate={required}>
        {({ input: { value, onChange }, meta: { error, dirty, touched } }) => (
          <>
            {domains.map(domain => (
              <Choice
                type="checkbox"
                id={domain.value}
                direction={"vertical"}
                mobile={false}
                label={domain.name}
                value={domain.value}
                checked={value.includes(domain.value)}
                disabled={
                  disabled ||
                  (value.some(
                    (domainValue: string) =>
                      domains.find(d => d.value === domainValue)?.is_anonymous,
                  ) &&
                    !domain.is_anonymous)
                }
                onChange={event => {
                  handleDomainChange(onChange, event, value, domains);
                }}
                background
                key={domain.value}
              />
            ))}
            {(touched || dirty) && error && <ErrorMsg>{error}</ErrorMsg>}
          </>
        )}
      </Field>
    </div>
  );
};

const languages = (languages: Array<any>) => {
  const languageLocaleElements: Array<any> = [];
  languages.forEach(locale =>
    languageLocaleElements.push({ value: locale.value, label: locale.name }),
  );
  return languageLocaleElements;
};

export const defaultPhoneCountry = (user: User) => {
  const userLocale = user.language;
  if (userLocale === "sv") {
    return "se";
  }
  if (userLocale === "en") {
    return "gb";
  }
  if (userLocale.includes("-")) {
    return userLocale.split("-")[1].toLowerCase();
  }
  return user.language;
};

const required = (value: any) => {
  const message = <FormattedMessage id="form_validation.required_field" />;
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : message;
  }
  return value ? undefined : message;
};

export const validatePhone = async (value: string) => {
  const { isValidPhoneNumber } = await import("libphonenumber-js/min");
  return isValidPhoneNumber(value) ? undefined : (
    <FormattedMessage id="form_validation.invalid_phone_number" />
  );
};
const validateEmail = (value: string) =>
  validationRegexp.email.test(value) ? undefined : (
    <FormattedMessage id="form_validation.invalid_email" />
  );

const InvitePatients = ({ client, ...props }: InvitePatientsProps) => {
  const [fixedStudyId, setFixedStudyId] = useState<string>();
  const user = useSelector(selectUser);

  // Do not allow submitting if ixrs invites are enabled but ixrs demographics are not set or treatment program mapped
  const hasIxrsErrors =
    SiteSettings.enable_ixrs_invites &&
    (props.ixrs_profile === null || props.treatment_program_id === null);

  // Get study id from querystring on mount
  useEffect(() => {
    const params = new URL(document.location.href).searchParams;
    const studyId = params.get("study_id");

    if (studyId) {
      setFixedStudyId(studyId);
    }
  }, []);

  const [isSaving, setIsSaving] = useState(false);
  const handleSave = async (values: any) => {
    setIsSaving(true);
    await save(values, props.intl, user);
    setIsSaving(false);
  };

  const domainIds = useMemo(
    () => props.domains.map(d => d.value),
    [props.domains],
  );

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        ...initialValues,
        treatment_program_id: props.treatment_program_id,
        study_id: fixedStudyId || initialValues.study_id,
        domain: props.domains.length === 1 ? domainIds : initialValues.domain,
        email: client.email,
        phone: client.phone,
        person_id: client.person_id,
        language: !client.language
          ? null
          : {
              label: props.intl.formatMessage({
                id: "languages." + client.language,
              }),
              value: client.language,
            },
        first_name: client.first_name,
        last_name: client.last_name,
        gender: client.gender,
      }}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} className="invite-patients-form">
            <InviteInformationDiv>
              <div className="sent-invites">
                <h3>
                  <FormattedMessage id="invites.new.invite_new_client" />
                  <LinkButton
                    holvikaari
                    size="small"
                    href={
                      user.role_id === "NonMedicalStaff"
                        ? "../manage/people#invites"
                        : "../clients#invites"
                    }
                  >
                    <FormattedMessage id="invites.new.view_sent_invites" />
                  </LinkButton>
                </h3>
              </div>
              <FormattedMessage id="invites.new.info" />
              <br />
              <br />
            </InviteInformationDiv>
            {SiteSettings.enable_ixrs_invites && props.ixrs_profile && (
              <IxrsProfileDetails profile={props.ixrs_profile} />
            )}
            <br />
            {props.domains.length > 1 && (
              <FormField name="mp_domain">
                {domainsCheckboxes(props.domains, hasIxrsErrors)}
              </FormField>
            )}
            <Field
              name="email"
              disabled={hasIxrsErrors}
              render={fieldProps => (
                <PlainFormField name="email" {...fieldProps} />
              )}
              validate={required || validateEmail}
            />
            {props.ask_phone_number && (
              <Field name="phone" validate={required || validatePhone}>
                {fieldProps => (
                  <FormField name="phone" {...fieldProps}>
                    <PhoneInput
                      disabled={hasIxrsErrors}
                      onChange={updatePhoneCountry}
                      defaultCountry={defaultPhoneCountry(props.user)}
                    />
                  </FormField>
                )}
              </Field>
            )}
            {props.ask_study_id && (
              <Field
                name="study_id"
                render={fieldProps => (
                  <PlainFormField
                    name="study_id"
                    {...fieldProps}
                    disabled={!!fixedStudyId}
                  />
                )}
                validate={required}
              />
            )}
            <Field
              id="language"
              name="language"
              render={fieldProps => (
                <FormField name="language" {...fieldProps}>
                  <Select
                    {...fieldProps}
                    options={languages(props.languages)}
                    onChange={({ value }: { value: string }) =>
                      fieldProps.input.onChange(value)
                    }
                    isDisabled={hasIxrsErrors}
                    placeholder={
                      <FormattedMessage id="invites.new.choose_language_placeholder" />
                    }
                  />
                </FormField>
              )}
              validate={required}
            />
            {props.ask_person_id &&
              // There is no anonymous domain selected
              !props.domains.some(
                (domain: DomainOption) =>
                  domain.is_anonymous &&
                  values.domain.some((d: string) => d === domain.value),
              ) && (
                <>
                  <Field
                    name="person_id"
                    render={fieldProps => (
                      <PlainFormField
                        name="person_id"
                        disabled={hasIxrsErrors}
                        {...fieldProps}
                      />
                    )}
                    validate={required}
                  />

                  <FieldDescription>
                    {SiteSettings.primary_person_id_format === "fi" ? (
                      <FormattedMessage id="invites.new.person_id_help" />
                    ) : (
                      <FormattedMessage id="invites.new.username_help_for_staff" />
                    )}
                  </FieldDescription>
                </>
              )}

            {hasIxrsErrors && (
              <Card
                type="alert"
                with-icon
                icon={<Icon size="large" name="alert_circle_48px" />}
              >
                <ul>
                  <li>
                    {!props.ixrs_profile && (
                      <FormattedMessage id="invites.new.error_fetching_ixrs_profile" />
                    )}
                  </li>
                  <li>
                    {props.ixrs_profile && !props.treatment_program_id && (
                      <FormattedMessage id="invites.new.no_treatment_program_will_be_assigned" />
                    )}
                  </li>
                </ul>
              </Card>
            )}
            <SubmitButton submit disabled={hasIxrsErrors || isSaving}>
              <FormattedMessage id="invites.new.send_invite" />
            </SubmitButton>
          </form>
        );
      }}
    />
  );
};
export default injectIntl(withErrorBoundary(InvitePatients));
