import Button from "@netmedi/frontend-design-system/dist/components/Button";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, ModalNav } from "shared/components/Modal/Modal.styles";
import {
  CloseModalIcon,
  DocumentContainer,
  ErrorWrapper,
  FileInputWrapper,
  FormFieldWrapper,
  HelperText,
  ModalContent,
  ModalHeader,
  StyledInput,
  MessageInput,
  Divider,
} from "./ShareDocumentModal.styles";
import { Dismiss } from "shared/components/Modal/Modal.types";
import SharedDocumentCard from "shared/components/DocumentCard/SharedDocumentCard";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FormField } from "common/components/Form";
import { FormApi } from "final-form";
import FileInput from "./FileInput";
import { showSuccessFlash } from "hcp/utils/flash";
import { SharedDocument } from "hcp/actions/sharedDocuments";
import { track } from "common/utils/api";
import { User } from "common/models/user";

export type ShareDocumentModalProps = {
  type: "share_document";
  confirm: () => void;
  files?: File[];
  clientId: number;
  clientName: string;
  personId: string;
  shareDocument: any;
  user: User;
};

interface ShareDocumentFormValues {
  documents: SharedDocument[];
  message: string;
}

const ShareDocumentModal = (
  props: Omit<ShareDocumentModalProps, "type"> & Dismiss,
) => {
  const intl = useIntl();

  const convertFileToSharedDocument = (file: File) => {
    return {
      name: file.name,
      file,
      description: "",
    } as SharedDocument;
  };

  const onFilesSelect = (
    files: File[],
    form: FormApi<ShareDocumentFormValues>,
  ) => {
    form.initialize({
      documents: form.getFieldState("documents")?.value,
      message:
        form.getFieldState("message")?.value ??
        intl.formatMessage({
          id: "shared_documents.default_message",
        }),
    });
    files.forEach((file: File) => {
      form.mutators.push("documents", convertFileToSharedDocument(file));
    });
  };

  const notEmpty = (value: string) => {
    return value
      ? undefined
      : intl.formatMessage({ id: "form_validation.required_field" });
  };

  const submitDisabled = (form: FormApi<ShareDocumentFormValues>) => {
    return (form.getFieldState("documents")?.length ?? 0) < 1;
  };

  const onSubmit = (data: ShareDocumentFormValues) => {
    track(
      "File shared from Patient Overview",
      {
        patient_id: props.clientId,
        user_id: props.user.id,
        domain: props.user.selected_domain,
        number_of_files: data.documents.length,
        category: "Conversations",
      },
      { patient_id: "people", user_id: "people" },
    );
    props
      .shareDocument(props.clientId, data.message, data.documents)
      .then(() => {
        props.dismiss();
        showSuccessFlash(
          intl.formatMessage({ id: "shared_documents.share_file_success" }),
        );
      });
  };

  return (
    <Modal borderRadius={true} onClick={(e: any) => e.stopPropagation()}>
      <ModalHeader>
        <CloseModalIcon name="remove_16px" onClick={props.dismiss} />
        <FormattedMessage
          tagName="h2"
          id="shared_documents.modal_title"
          values={{
            patient_name: props.clientName,
            patient_id: props.personId,
          }}
        />
      </ModalHeader>
      <ModalContent>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            documents: (props.files ?? []).map(file =>
              convertFileToSharedDocument(file),
            ),
            message: intl.formatMessage({
              id: "shared_documents.default_message",
            }),
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  {values.documents.length > 0 ? (
                    <>
                      <FieldArray name="documents">
                        {fieldsProps =>
                          fieldsProps.fields.map((name, index) => (
                            <div key={values.documents[index].name}>
                              {index !== 0 && <Divider />}
                              <DocumentContainer>
                                <SharedDocumentCard
                                  type="file"
                                  sharedDocument={values.documents[index]}
                                  fit_parent={true}
                                  onRemove={() =>
                                    form.mutators.remove("documents", index)
                                  }
                                />
                              </DocumentContainer>
                              <Field
                                name={`${name}.description`}
                                render={fieldsProps => (
                                  <ErrorWrapper>
                                    <FormField
                                      labelOnTop
                                      label="shared_documents.file_description"
                                      {...fieldsProps}
                                    >
                                      <StyledInput
                                        type="text"
                                        placeholder={intl.formatMessage({
                                          id: "shared_documents.description_placeholder",
                                        })}
                                        {...fieldsProps.input}
                                      />
                                    </FormField>
                                    <FormattedMessage
                                      tagName={HelperText}
                                      id="shared_documents.description_note"
                                    />
                                  </ErrorWrapper>
                                )}
                              />
                            </div>
                          ))
                        }
                      </FieldArray>
                    </>
                  ) : (
                    <FormattedMessage
                      tagName="p"
                      id="shared_documents.select_at_least_one_file"
                    />
                  )}
                  <FileInputWrapper>
                    <FileInput
                      onFilesSelected={(files: File[]) =>
                        onFilesSelect(files, form)
                      }
                      multiple
                      buttonIcon="add_circle_16px"
                      buttonMessage="shared_documents.browse"
                    />
                  </FileInputWrapper>
                  <Field
                    name="message"
                    validate={notEmpty}
                    render={fieldsProps => (
                      <ErrorWrapper>
                        <FormFieldWrapper>
                          <FormField
                            labelOnTop
                            required
                            showRequiredIndicator
                            label="shared_documents.message"
                            {...fieldsProps}
                          >
                            <MessageInput type="text" {...fieldsProps.input} />
                          </FormField>
                        </FormFieldWrapper>
                        <FormattedMessage
                          tagName={HelperText}
                          id="shared_documents.message_helper_text"
                        />
                      </ErrorWrapper>
                    )}
                  />
                  <ModalNav align="right">
                    <Button
                      size="default"
                      data-testid="modal-submit-button"
                      disabled={submitDisabled(form)}
                      submit
                      icon="send"
                    >
                      <FormattedMessage id="conversations.submit" />
                    </Button>
                  </ModalNav>
                </form>
              </>
            );
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default ShareDocumentModal;
