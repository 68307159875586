import React, { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  alertText,
  HeaderDate,
  IntervalOption,
  shouldHideRow,
  SymptomTableProps,
  timeSlots,
  VALID_INTERVALS,
} from "../SymptomTable/SymptomTable";
import { FormattedMessage, injectIntl } from "react-intl";

import { partition, orderBy, debounce, last } from "lodash";
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";
import { Spinner } from "@netmedi/frontend-design-system";
import { useStateCallback } from "shared/hooks/useStateCallback";
import { StepsProps } from "./types";
import { SymptomTableSteps } from "../SymptomTable/SymptomTableSteps";
import { SiteSettings } from "common/utils/holvikaari";
import { SymptomTableStepsGraph } from "../SymptomTable/SymptomTableStepsGraph";
import { bundleParentRows, findMatchingGrading } from "../SymptomTable/helpers";
import SymptomTableCompactRows from "./SymptomTableCompactRows";
import {
  IntervalSelect,
  LoaderContainer,
  SymptomNameCell,
  SymptomName,
  ToggleGradeSymptoms,
  SymptomTable,
  DateRows,
  DateRow,
  DateRowScroller,
  VerticallyHiddenDateRow,
  SymptomNameLabel,
  ScrollableWrapper,
} from "./SymptomTableCompact.styles";

function toggleGradient(node: HTMLDivElement): void {
  const leftSideX = 0;
  const rightSideX = Math.round(node.scrollWidth - node.offsetWidth);

  node.removeAttribute("data-hide-left-gradient");
  node.removeAttribute("data-hide-right-gradient");

  if (node.scrollLeft === leftSideX) {
    node.setAttribute("data-hide-left-gradient", "true");
  }

  if (node.scrollLeft >= rightSideX) {
    node.setAttribute("data-hide-right-gradient", "true");
  }
}

function onScroll(evt: React.UIEvent<HTMLDivElement>): void {
  toggleGradient(evt.currentTarget);
}

function scrollToRight(node: HTMLDivElement): void {
  if (!node) {
    return;
  }

  const selector = "[data-is-empty-scroller-column='false']";
  const columnsWithSymptoms: Element[] = Array.from(
    document.querySelectorAll(selector),
  );
  const hasColumnsWithSymptoms: boolean = columnsWithSymptoms.length > 0;

  if (hasColumnsWithSymptoms) {
    const lastColumn = last(columnsWithSymptoms);
    lastColumn?.scrollIntoView({
      block: "end",
    });
  } else if (typeof node.scroll === "function") {
    node.scroll(node.scrollWidth, 0);
  }

  toggleGradient(node);
}

function scrollToRightRAF(node: HTMLDivElement): void {
  window.requestAnimationFrame(() => {
    scrollToRight(node);
  });
}

// eslint-disable-next-line max-statements
const SymptomTableCompact = (props: SymptomTableProps & StepsProps) => {
  const [hideZeroRows, setHideZeroRows] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useStateCallback<boolean>(false);

  const {
    intl,
    rows,
    ending,
    interval,
    steps,
    getSteps,
    startDate,
    endDate,
    client_id,
  } = props;

  const stepsLength: number = steps.data?.observations.length || 0;
  const shouldRenderSteps =
    SiteSettings.enable_device_generated_data &&
    steps !== undefined &&
    interval === steps.interval &&
    stepsLength > 0;
  const rowsLength: number = rows[0]?.data?.length || 0;
  const columns: number = stepsLength > rowsLength ? stepsLength : rowsLength;
  const lastStepDayjs: Dayjs = dayjs(
    last(steps.data?.observations)?.start_time,
    undefined,
    true,
  );
  const endingDayjs: Dayjs = dayjs(ending);
  const endingDate: Dayjs =
    shouldRenderSteps && lastStepDayjs.isAfter(endingDayjs)
      ? lastStepDayjs
      : endingDayjs;
  const columnTimes = timeSlots(columns, endingDate, interval);

  const parentRows = bundleParentRows(rows);

  // Adding a boolean to each symptom indicating whether the row has only 0 grades = should be hidden
  const rowsWithHideBool = parentRows.map(row => ({
    ...row,
    hide: shouldHideRow(row),
  }));

  let numOfHiddenRows = rowsWithHideBool.filter(row => row.hide).length;

  let [hiddenRows, visibleRows] = partition(
    rowsWithHideBool,
    row => hideZeroRows && row.hide,
  );

  hiddenRows = orderBy(
    hiddenRows,
    row => row?.data?.filter(data => data),
    "desc",
  );

  const numOfVisibleRows = visibleRows.length;
  // We want to show always at least 4 rows, even if they have only zero grades
  if (numOfVisibleRows < 4) {
    const numOfAddedZeroRows = 4 - numOfVisibleRows;
    visibleRows = visibleRows.concat(hiddenRows.slice(0, numOfAddedZeroRows));
    numOfHiddenRows =
      numOfHiddenRows > numOfAddedZeroRows
        ? numOfHiddenRows - numOfAddedZeroRows
        : 0;
  }

  const [symptomTableRef, setSymptomTableRef] = useState<HTMLDivElement | null>(
    null,
  );

  const symptomTableRefCallback = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setSymptomTableRef(node);
    }
  }, []);

  const optionIntervals: IntervalOption[] = VALID_INTERVALS.map(x => ({
    value: x,
    label: intl.formatMessage({
      id: `symptom_table.date_intervals.${x}`,
    }),
  }));

  function scrollTableToRight(): void {
    if (symptomTableRef) {
      scrollToRightRAF(symptomTableRef);
    }
  }

  const debouncedHandleResize = debounce(scrollTableToRight, 200);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [symptomTableRef]);

  useEffect(() => {
    if (SiteSettings.enable_device_generated_data) {
      getSteps(client_id, {
        interval,
        start_date: startDate,
        end_date: endDate,
      });
    }
  }, []);

  useEffect(scrollTableToRight, [steps]);

  const renderDateRows = () => (
    <DateRows data-testid="symptom-table-visible-date-headers">
      <ScrollSyncNode selfLockAxis="X" scroll="synced-only">
        <DateRowScroller>
          {columnTimes.map(([start, end], idx) => {
            const matchGrading = findMatchingGrading({
              start: start.valueOf(),
              end: end.valueOf(),
              interval,
            });
            const gradesForColumn = visibleRows
              .map(matchGrading)
              .filter(Boolean);
            const isEmpty = gradesForColumn.length === 0;
            return (
              <DateRow
                key={"visible-date-row" + idx}
                dataIsEmpty={isEmpty}
                data-is-empty={isEmpty}
              >
                <HeaderDate
                  start={start}
                  end={end}
                  interval={interval}
                  index={idx}
                  maxIndex={columnTimes.length - 1}
                  isOverlapping={false}
                />
              </DateRow>
            );
          })}
        </DateRowScroller>
      </ScrollSyncNode>
    </DateRows>
  );

  const renderSymptomTable = () => (
    <SymptomTable>
      <thead>
        <tr>
          {columnTimes.map(([start, end], idx) => {
            const matchGrading = findMatchingGrading({
              start: start.valueOf(),
              end: end.valueOf(),
              interval,
            });
            const gradesForColumn = visibleRows
              .map(matchGrading)
              .filter(Boolean);
            const isEmpty = gradesForColumn.length === 0;

            return (
              <VerticallyHiddenDateRow
                key={"hidden-date-row" + idx}
                data-is-empty-scroller-column={isEmpty}
              >
                <HeaderDate
                  start={start}
                  end={end}
                  interval={interval}
                  index={idx}
                  maxIndex={columnTimes.length - 1}
                  isOverlapping={false}
                />
              </VerticallyHiddenDateRow>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <SymptomTableCompactRows
          rows={visibleRows}
          interval={interval}
          columnTimes={columnTimes}
        />

        {shouldRenderSteps && (
          <>
            <tr>
              <SymptomNameCell colSpan={columnTimes.length}>
                <SymptomName>
                  <FormattedMessage id="symptom_table.steps.footsteps" />
                  <SymptomNameLabel>
                    {interval === "day" ? (
                      <FormattedMessage id="symptom_table.steps.total" />
                    ) : (
                      <FormattedMessage id="symptom_table.steps.daily_average" />
                    )}
                  </SymptomNameLabel>
                </SymptomName>
              </SymptomNameCell>
            </tr>
            <tr data-testid="symptom-table-steps-row">
              <SymptomTableSteps
                columnTimes={columnTimes}
                interval={interval}
                steps={steps}
              />
            </tr>
            <tr>
              <SymptomTableStepsGraph
                isCompact={true}
                columnTimes={columnTimes}
                interval={interval}
                steps={steps}
              />
            </tr>
          </>
        )}
      </tbody>
    </SymptomTable>
  );

  return (
    <>
      <>
        <IntervalSelect
          value={optionIntervals.find(o => o.value === interval)}
          options={optionIntervals}
          onChange={(option: IntervalOption) => {
            props.onIntervalChange(option.value);
            if (SiteSettings.enable_device_generated_data) {
              getSteps(client_id, {
                interval: option.value,
                start_date: startDate,
                end_date: endDate,
              });
            }
            setIsLoading(true, () => {
              window.requestAnimationFrame(() => {
                setIsLoading(false);
                if (symptomTableRef) {
                  scrollToRight(symptomTableRef);
                }
              });
            });
          }}
          isClearable={false}
          isSearchable={false}
        />
      </>

      {isLoading && (
        <LoaderContainer>
          <Spinner noPadding />
        </LoaderContainer>
      )}

      {!isLoading && (
        <>
          <ScrollSync>
            <>
              {renderDateRows()}
              <ScrollSyncNode scroll="syncer-only">
                <ScrollableWrapper
                  ref={symptomTableRefCallback}
                  onScroll={onScroll}
                >
                  {renderSymptomTable()}
                </ScrollableWrapper>
              </ScrollSyncNode>
            </>
          </ScrollSync>
          {numOfHiddenRows > 0 && (
            <ToggleGradeSymptoms
              tabIndex={0}
              role="button"
              onKeyPress={e => {
                e.preventDefault();
                setHideZeroRows(!hideZeroRows);
              }}
              onClick={e => {
                e.preventDefault();
                setHideZeroRows(!hideZeroRows);
              }}
            >
              {alertText({
                hideRows: hideZeroRows,
                numOfRows: numOfHiddenRows,
                viewedAsStaff: props.viewedAsStaff,
              })}
            </ToggleGradeSymptoms>
          )}
        </>
      )}
    </>
  );
};

export default injectIntl(SymptomTableCompact);
