import React from "react";
import { LeftNav } from "./NavBar";
import { FormattedMessage } from "react-intl";
import { icons } from "./SignedInNavBar";

export default function OverflowDropdown({ elements, moreId }: Props) {
  const overflowDropdownRef = React.useRef<HTMLDivElement>(null);
  const moreDropdownRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    const collapsedNavbarMQ =
      window && window.matchMedia
        ? window.matchMedia("(max-width: 1050px)")
        : { matches: true };

    const showDropDownIfNeeded = () => {
      if (collapsedNavbarMQ.matches) return; // If the navbar is collapsed, we don't have to do anything

      if (!overflowDropdownRef.current || !moreDropdownRef.current) return;

      // Set all elements in the dropdown and navbar to visible
      moreDropdownRef.current.classList.remove("dropdownVisible");
      Array.from(moreDropdownRef.current.querySelectorAll("li")).forEach(
        element => element.classList.remove("hiddenElement"),
      );

      const elements = Array.from(
        overflowDropdownRef.current.querySelectorAll(".elements > .nav > li"),
      );
      elements.forEach(element => element.classList.remove("hiddenElement"));

      // Check which elements don't fit to the navbar
      const initialHiddenElements = elements.filter(
        element =>
          !isElementVisible(
            element as HTMLElement,
            overflowDropdownRef.current as any,
          ),
      );

      // if all elements fit to the navbar, no need to do anything
      if (initialHiddenElements.length === 0) return;

      // show more dropdown
      moreDropdownRef.current.classList.add("dropdownVisible");

      // If there's more enough room for the "more" dropdown to be visible,
      // start hiding elements until it's visible
      while (
        !isElementVisible(moreDropdownRef.current, overflowDropdownRef.current)
      ) {
        // hide last visible element in navbar
        Array.from(
          overflowDropdownRef.current.querySelectorAll(
            ".elements > .nav > li:not(.hiddenElement)",
          ),
        )
          .pop()
          ?.classList.add("hiddenElement");

        // Makes the last non-visible element in the dropdown visible
        Array.from(moreDropdownRef.current.querySelectorAll("li.hiddenElement"))
          .slice(-1)
          .forEach(element => element?.classList.remove("hiddenElement"));
      }

      // Getting the hidden elements again as their number changes in the above loop
      const hiddenElements = elements.filter(
        element =>
          !isElementVisible(
            element as HTMLElement,
            overflowDropdownRef.current as any,
          ),
      );

      // Hiding the non-hidden elements in the dropdown
      Array.from(moreDropdownRef.current.querySelectorAll("li"))
        .slice(0, elements.length - hiddenElements.length)
        .forEach(element => element.classList.add("hiddenElement"));

      return;
    };

    // Fit the links to nabvar at mount
    showDropDownIfNeeded();
    // Recalculate on window resize
    window.addEventListener("resize", showDropDownIfNeeded);
    // Recalculate when fonts are loaded
    document.fonts?.ready.then(showDropDownIfNeeded);
    // Recalculate after the logo img has been loaded
    const brandImg = document.querySelector(".navbar-inner .brand img");
    brandImg && brandImg.addEventListener("load", showDropDownIfNeeded);

    return () => {
      window.removeEventListener("resize", showDropDownIfNeeded);
      brandImg && brandImg.removeEventListener("load", showDropDownIfNeeded);
    };
  }, [overflowDropdownRef.current, moreDropdownRef.current]);

  return (
    <LeftNav
      className="overflowNavBar"
      ref={overflowDropdownRef}
      navElements={[
        <li key={0} className="elements">
          <ul className="nav menu">
            {elements.map((element, idx) => (
              <React.Fragment key={idx}>{element}</React.Fragment>
            ))}
          </ul>
        </li>,
        <li key={1} className="dropdown" ref={moreDropdownRef}>
          <a
            href="#"
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
          >
            {icons["kebab"]}
            <span>
              <FormattedMessage id={moreId} />
            </span>{" "}
            {icons["direction_down"]}
          </a>
          <ul className="dropdown-menu" role="menu">
            {elements.map((element, idx) => (
              <React.Fragment key={idx}>{element}</React.Fragment>
            ))}
          </ul>
        </li>,
      ]}
    />
  );
}

function isElementVisible(element: HTMLElement, container: HTMLElement) {
  const parentRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  const isElementInsideParentRectX =
    Math.round(elementRect.left) >= Math.round(parentRect.left) &&
    Math.round(elementRect.left + elementRect.width) <=
      Math.round(parentRect.left + parentRect.width);
  const isElementInsideParentRectY =
    Math.round(elementRect.top) >= Math.round(parentRect.top) &&
    Math.round(elementRect.top + elementRect.height) <=
      Math.round(parentRect.top + parentRect.height);

  return isElementInsideParentRectX && isElementInsideParentRectY;
}

type Props = {
  elements: React.ReactElement[];
  moreId: string;
};
