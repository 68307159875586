import bugsnag, { Breadcrumb, BrowserConfig } from "@bugsnag/js";
import { SiteSettings } from "common/utils/holvikaari";
import BugsnagPerformance from "@bugsnag/browser-performance";

declare global {
  interface Window {
    Bugsnag: typeof Bugsnag;
    bugsnagOnBreadcrumb: any;
  }
}

// We need to attach this function to the `window` object
// for ease of access in testing environment
window.bugsnagOnBreadcrumb = (bc: Breadcrumb) => {
  if (bc.metadata && bc.metadata.targetText) {
    bc.metadata.targetText = "[REDACTED]";
  }
  return bc;
};

const isNodeTestEnv = process.env.NODE_ENV === "test";

// Should not post to Bugsnag if saved locally (proto == "file:")
const enableBugsnag =
  !isNodeTestEnv || process.env.TEST || location.protocol === "https:";

const apiKey =
  (enableBugsnag && process.env.BUGSNAG_FRONTEND) ||
  "deadbeefdeadbeefdeadbeefdeadbeef";

const sharedOptions = {
  apiKey,
  appVersion: process.env.VERSION || "test",
  generateAnonymousId: false,
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
};

const BugsnagOptions: BrowserConfig = {
  ...sharedOptions,
  onBreadcrumb: window.bugsnagOnBreadcrumb,
};

const Bugsnag = bugsnag.start(BugsnagOptions);

export const setupBugsnag = (user: any) => {
  Bugsnag.setUser(`${SiteSettings.site}-${user.id}`);
  Bugsnag.addMetadata("user", {
    id: user.id,
    site: SiteSettings.site,
  });
};

window.Bugsnag = Bugsnag;

const BugsnagPerformanceOptions = {
  ...sharedOptions,
  bugsnag: Bugsnag,
};

export const startBugsnagPerformance = () =>
  BugsnagPerformance.start(BugsnagPerformanceOptions);

export default Bugsnag;
