import { Typography } from "common/components/Typography/Typography.styles";
import React from "react";
import {
  DocumentContainer,
  IconBase,
  StyledIcon,
  TextArea,
  FileTitle,
  FileDescription,
  FileCategory,
  FileDate,
  IconContainer,
} from "./DocumentCard.styles";
import { FormattedDate } from "react-intl";

export type Document = {
  name: string;
  id?: string;
  url?: string;
  description?: string;
  category?: string;
  date?: string;
  sender?: string;
  domain?: string;
};

type SharedDocumentProps = {
  type: string;
  sharedDocument: Document;
  fit_parent?: boolean;
  onRemove?: () => void;
  urlAppendix?: string;
};

const SharedDocumentCard = ({
  type,
  sharedDocument,
  fit_parent = false,
  onRemove,
  urlAppendix = "",
}: SharedDocumentProps) => {
  const iconType: "file" | "new_tab" = type === "link" ? "new_tab" : "file";
  const { date } = sharedDocument;

  const urlWithAppendix = sharedDocument.url
    ? sharedDocument.url + urlAppendix
    : undefined;

  return (
    <Typography>
      <DocumentContainer
        href={urlWithAppendix}
        target="_blank"
        fit_parent={fit_parent}
        unclickable={true}
      >
        <IconBase>
          <StyledIcon name={iconType} size="medium" />
        </IconBase>
        <TextArea>
          {sharedDocument.category && (
            <FileCategory>{sharedDocument.category}</FileCategory>
          )}
          {date && (
            <FileDate>
              <FormattedDate
                value={date}
                year="numeric"
                month="long"
                day="numeric"
                weekday="long"
              />
            </FileDate>
          )}
          <FileTitle>{sharedDocument.name}</FileTitle>
          {sharedDocument.description && (
            <FileDescription>{sharedDocument.description}</FileDescription>
          )}
        </TextArea>
        <IconContainer>
          {onRemove ? (
            <StyledIcon name="remove" size="small" onClick={onRemove} />
          ) : (
            <StyledIcon name="arrow_right" size="medium" />
          )}
        </IconContainer>
      </DocumentContainer>
    </Typography>
  );
};

export default SharedDocumentCard;
